<template>
  <v-alert v-if="textAlert" class="alert-style" prominent color="#FFE1DF">
    <v-row align="center" no-gutters>
      <v-icon class="mr-2 pl-2 pr-2" color="#F23528"> mdi-alert </v-icon>
      <v-col class="grow">
        {{ textAlert }}
      </v-col>
      <v-col class="shrink pr-3">
        <font-awesome-icon
          class="icon-style"
          color="#F23528"
          icon="window-close"
          @click="close"
        />
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
import { EventBus } from '@/utils/eventBus'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AlertLoginError',

  data() {
    return {
      textAlert: '',
    }
  },

  created() {
    EventBus.$on('notify-login', (text) => {
      this.textAlert = text
    })
  },

  watch: {
    getApiError: {
      handler(error) {
        if (error) this.textAlert = this.$t(error.key)
      },
      immediate: true,
    },
  },

  computed: {
    ...mapGetters('apiManager', ['getApiError']),
  },

  methods: {
    ...mapActions('apiManager', ['resetApiErrorMessage']),
    close() {
      this.resetApiErrorMessage()
      this.textAlert = ''
    },
  },

  beforeDestroy() {
    EventBus.$off('notify-login')
  },
}
</script>

<style scoped>
.alert-style {
  font-family: 'Rubik';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border-radius: 8px !important;
  width: 100%;
  height: fit-content;
}
.icon-style {
  cursor: pointer;
}
</style>
